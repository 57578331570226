import React from "react"

import Layout from "../components/layout";
import Home from "../components/home";
import Image from "../components/image";
import SEO from "../components/seo";
import 'antd/dist/antd.css';

const IndexPage = (props) => {
  return (
  <Layout>
    <SEO title="RealtyStack - Home" />
    <Home />
  </Layout>
)}

export default IndexPage
