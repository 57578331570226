import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import { Row, Col, Button, Card, Carousel } from 'antd';
import { ArrowRightOutlined, DollarOutlined, CreditCardOutlined, FileDoneOutlined } from '@ant-design/icons';
import asset35 from '../assets/Asset 35.svg';
import hero from '../assets/hero.svg';
import investor from '../assets/investor.svg';
import landlord from '../assets/landlord.svg';
import resident from '../assets/resident.svg';
import demoSite1 from '../assets/demo-site-1.png';
import demoSite2 from '../assets/demo-site-2.png';
import demoSite3 from '../assets/demo-site-3.png';
import demoSite4 from '../assets/demo-site-4.png';
import units from '../assets/units.png';
import {Fade} from 'react-reveal';
import { isMobile } from "react-device-detect";
import heroSquare from '../assets/hero-square.svg';

const Home = () => {
    const [mobileLayout, setMobileLayout] = useState(false)
    const [width, setWidth] = useState(typeof window !== `undefined` ? window.innerWidth : 1000)

    useEffect(() => {
      const handleResize = () => {
        setWidth(window.innerWidth)
        setMobileLayout(window.innerWidth <= 760)
      }
      window.addEventListener("resize", handleResize)
      return () => { window.removeEventListener("resize", handleResize) }
    }, [])

    return (<div style={{overflow:'hidden'}}>
      <div 
        style={{
          background: '#002140',
          transform: 'skewY(-6deg)',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          height: '100%',
          // backgroundImage: linear-gradient(#ff9d2f, #ff6126),
          transformOrigin: 'top left',
        }}
        >
      </div>
      {isMobile || mobileLayout ? null :
      <div
      style={{
        top:100,
        left: '90%',
        position:'absolute'
      }}
      >
        <span style={{
          height: '50px',
          width: '50px',
          backgroundColor: '#43c46394',
          borderRadius: '50%',
          display: 'inline-block',
          top:0,
          left: -50,
          position:'relative'
        }}>
        </span>
        <span style={{
          height: '90px',
          width: '90px',
          backgroundColor: '#43c46394',
          borderRadius: '50%',
          display: 'inline-block',
          top:10,
          left:-100,
          position:'absolute'
        }}>
        </span>
        <span style={{
          height: '25px',
          width: '25px',
          backgroundColor: '#43c46394',
          borderRadius: '50%',
          display: 'inline-block',
          top: 350,
          left:-25,
          position:'absolute'
        }}>
        </span>
        <span style={{
          height: '40px',
          width: '40px',
          backgroundColor: '#e08e2c',
          borderRadius: '50%',
          display: 'inline-block',
          top:160,
          left:-40,
          position:'absolute'
        }}>
        </span>
      </div>
      }
      <div
      style={{
        top:80,
        left:40,
        position:'absolute'
      }}
      >
        {/* <span style={{
          height: '50px',
          width: '50px',
          backgroundColor: '#43c46394',
          borderRadius: '50%',
          display: 'inline-block',
          top:300,
          left: 0,
          position:'relative'
        }}>
        </span> */}
        <span style={{
          height: '70px',
          width: '70px',
          backgroundColor: '#43c46394',
          borderRadius: '50%',
          display: 'inline-block',
          top:20,
          left:10,
          position:'absolute'
        }}>
        </span>
        <span style={{
          height: '25px',
          width: '25px',
          backgroundColor: '#f7941d',
          borderRadius: '50%',
          display: 'inline-block',
          top: 150,
          left:70,
          position:'absolute'
        }}>
        </span>
        <span style={{
          height: '60px',
          width: '60px',
          backgroundColor: '#e08e2c',
          borderRadius: '50%',
          display: 'inline-block',
          top:0,
          left:50,
          position:'absolute'
        }}>
        </span>
        <span style={{
          height: '70px',
          width: '70px',
          backgroundColor: '#43c46394',
          borderRadius: '50%',
          display: 'inline-block',
          top:435,
          left:100,
          position:'absolute'
        }}>
        </span>
      </div>
      
      <div 
      style={{background:'none', padding: '84px 10px'}}
      >
        <Row justify='center' style={{marginTop:'150px', backgroundColor:'none'}}>
          <Col xs={22} sm={22} md={9} lg={8}>
            <h1 style={{color:'#fff', fontSize:'32px', marginBottom:'30px'}}>Simple property management</h1>
            <p style={{color:'#d9fcff', fontSize:'24px', marginBottom:'40px', fontFamily:'Montserrat'}}>Focus on building and growing your portfolio rather than maintaining it</p>
            <div style={{marginBottom:'12px'}}>      
            <a href={`${process.env.GATSBY_REALTYSTACK_APP_URL}/signup/`}>
              <Button size='large' type='primary' style={{marginRight:'30px', background:'rgb(67, 196, 99)', border:'none'}}>
                Get Started
              </Button>
            </a>
            <Link to='/features/'>
              <Button ghost size='large'>Learn More</Button>
            </Link>
            </div>  
          </Col>
          <Col xs={22} sm={22} md={9} lg={8}>
            <img className="banner" alt='banner' src={hero} style={{zIndex:100}}/>
          </Col>
        </Row>
      </div>
      {/* <img className="banner" src={asset35} style={{zIndex:100}}/> */}
      <div style={{minHeight:'800px', background:'#fff', textAlign:'center', verticalAlign:'middle', paddingTop:'385px', paddingLeft:'10px', paddingRight:'10px'}}>
        <h2 style={{fontSize:'36px', fontWeight:'400', letterSpacing:'-.9px'}}>Easily manage and scale</h2>
        <p style={{fontSize:'20px', marginBottom:'60px', color:'rgba(3,27,78,.7)'}}>Build your business using tools to track aquisitions, get tenants, and manage your properties</p>
        <Fade bottom>        
          <Row type='flex' justify='center'>
            <Col xs={22} sm={22} md={8} lg={6} style={{margin:'10px'}}>
              <Card cover={<img style={{ paddingTop:'27px'}} src={investor} alt='Investors' />} hoverable>
                <h3 style={{}}>Investors</h3>
                <p>Build and manage your porfolio and business</p>
              </Card>
            </Col>
            <Col xs={22} sm={22} md={8} lg={6} style={{margin:'10px'}}>
              <Card cover={<img style={{maxHeight:'240px'}} src={landlord} alt='Landlords' />} hoverable>
                <h3>Landlords</h3>
                <p>Manage properties and collect rent from residents</p>
              </Card>
            </Col>
            <Col xs={22} sm={22} md={8} lg={6} style={{margin:'10px'}}>
              <Card cover={<img style={{maxHeight:'232px'}} src={resident} alt='Residents'  />} hoverable>
                <h3>Residents</h3>              
                <p>Pay rent online to your property owner</p>
              </Card>
            </Col>
          </Row>
        </Fade>
      </div>
      <div style={{minHeight:'800px', background:'#f0f2f5', textAlign:'center', verticalAlign:'middle', paddingTop:'120px'}}>
        <h2 style={{fontSize:'36px', fontWeight:'400', letterSpacing:'-.9px'}}>Built for porfolios of all sizes</h2>
        <p style={{fontSize:'20px', marginBottom:'60px', color:'rgba(3,27,78,.7)'}}>Focus more time on building your business and less on managing it. Asset Management, Deal Aquisitions, Capital Raising, </p>
        <Row type="flex" justify="center">
          <Col xs={22} sm={22} md={16} lg={12}>
          <Carousel style={{boxShadow:'0px 0px 28px -8px rgba(0,0,0,0.85)', marginBottom:0}} effect="fade" dots={true} autoplay={true}>
            <img style={{maxHeight:'280px', marginBottom:0}} src={demoSite1}  alt='App properties page' />
            <img style={{maxHeight:'280px', marginBottom:0}} src={demoSite2} alt='App todos page'  />
            <img style={{maxHeight:'280px', marginBottom:0}} src={demoSite3} alt='App contacts'  />
            <img style={{maxHeight:'280px', marginBottom:0}} src={demoSite4} alt='App property page'  />
          </Carousel>
          </Col>
        </Row>
      </div>
      <div style={{minHeight:'600px', background:'#fff', textAlign:'center', verticalAlign:'middle', paddingTop:'120px'}}>
        <h2 style={{fontSize:'36px', fontWeight:'400', letterSpacing:'-.9px'}}>All the features your business needs</h2>
        <p style={{fontSize:'20px', marginBottom:'60px', color:'rgba(3,27,78,.7)'}}>Build your business using tools to track aquisitions, get tenants, and manage your properties</p>
        <Row type="flex" justify="center">
          <Col xs={22} sm={22} md={8} lg={6} style={{textAlign:'center', padding:'20px'}}>
            <div style={{marginBottom:'15px'}}><DollarOutlined style={{fontSize:'42px'}} /></div>
            <h2>Predictable Pricing</h2>
            <div style={{textAlign:'left'}}>
              <p>Three unique pricing plans to meet all of your needs</p>
              <Link style={{color:'#005cb9', fontWeight:500}} to='/pricing/'>Pricing <ArrowRightOutlined /></Link>
            </div>
          </Col>
          <Col xs={22} sm={22} md={8} lg={6} style={{textAlign:'center', padding:'20px'}}>
            <div style={{marginBottom:'15px'}}><CreditCardOutlined style={{fontSize:'42px'}} /></div>
            <h2>Secure Payments</h2>
            <div style={{textAlign:'left'}}>
              <p>Recieve rent, security deposits, and other fees through our secure payments provider</p>
              <Link style={{color:'#005cb9', fontWeight:500}} to='/features/#payments'>Payments <ArrowRightOutlined /></Link>
            </div>
          </Col>
          <Col xs={22} sm={22} md={8} lg={6} style={{textAlign:'center', padding:'20px'}}>
            <div style={{marginBottom:'15px'}}><FileDoneOutlined style={{fontSize:'42px'}} /></div>
            <h2>Easy Reporting</h2>
            <div style={{textAlign:'left'}}>
              <p>Track financials, residents, and potential deals</p>
              <Link style={{color:'#005cb9', fontWeight:500}} to='/features/#residents'>About <ArrowRightOutlined /></Link>
            </div>
          </Col>
        </Row>
      </div>
      {/* <img height='200px' width='100%' className='squares-pattern' /> */}
      <div style={{height:'300px', background:'linear-gradient(to bottom right,#005cb9,rgb(0, 105, 255))', textAlign:'center', verticalAlign:'middle', paddingTop:'100px'}}>
        <h2 style={{fontSize:'36px', fontWeight:'400', letterSpacing:'-.9px', color:'#fff'}}>Get Started Now</h2>
        <a href={`${process.env.GATSBY_REALTYSTACK_APP_URL}/signup/`}>
          <Button size='large' type='primary' style={{ background:'rgb(67, 196, 99)', border:'none'}}>
            Create an account
          </Button>
        </a>
      </div>
    </div>
)}

export default Home;